@font-face {
  font-family: "Axiforma";
  font-weight: 400;
  src: url("../font/AxiformaRegular.otf") format("opentype");
}

@font-face {
  font-family: "Axiforma";
  font-weight: 900;
  src: url("../font/AxiformaExtraBold.otf") format("opentype");
}
