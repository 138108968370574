:root {
  --theme-height--svh: 100svh;
  --theme-height--header: 4rem;
  --theme-height--svh-header: calc(100svh - 6rem);
  --theme-height--svh-header-pdf: calc(100svh - 4.5rem);
  --theme-color--brand-red: #f2254d;
  --theme-color--brand-red-contrast: #ffffff;
  --theme-color--brand-blue: #4127ff;
  --theme-color--brand-dark: #262628;
  --theme-color--brand-light: #f9f9f9;
}
body {
  font-family: "Axiforma", sans-serif;
}
